import { IconSpeakerStats } from '../../base/icons/svg';
import AbstractButton from '../../base/toolbox/components/AbstractButton';
/**
 * Implementation of a button for opening speaker stats dialog.
 */
class AbstractSpeakerStatsButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.speakerStats';
        this.icon = IconSpeakerStats;
        this.label = 'toolbar.speakerStats';
        this.tooltip = 'toolbar.speakerStats';
    }
}
export default AbstractSpeakerStatsButton;
