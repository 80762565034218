import React from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../base/i18n/functions';
import { IconUsers } from '../../../base/icons/svg';
import { getParticipantCount } from '../../../base/participants/functions';
import AbstractButton from '../../../base/toolbox/components/AbstractButton';
import { close as closeParticipantsPane, open as openParticipantsPane } from '../../../participants-pane/actions.web';
import { closeOverflowMenuIfOpen } from '../../../toolbox/actions.web';
import { isParticipantsPaneEnabled } from '../../functions';
/**
 * Implementation of a button for accessing participants pane.
 */
class ParticipantsPaneButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.toggledAccessibilityLabel = 'toolbar.accessibilityLabel.closeParticipantsPane';
        this.icon = IconUsers;
        this.label = 'toolbar.participants';
        this.tooltip = 'toolbar.participants';
        this.toggledTooltip = 'toolbar.closeParticipantsPane';
    }
    /**
     * Indicates whether this button is in toggled state or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isToggled() {
        return this.props._isOpen;
    }
    /**
    * Handles clicking the button, and toggles the participants pane.
    *
    * @private
    * @returns {void}
    */
    _handleClick() {
        const { dispatch, _isOpen } = this.props;
        dispatch(closeOverflowMenuIfOpen());
        if (_isOpen) {
            dispatch(closeParticipantsPane());
        }
        else {
            dispatch(openParticipantsPane());
        }
    }
    /**
     * Override the _getAccessibilityLabel method to incorporate the dynamic participant count.
     *
     * @override
     * @returns {string}
     */
    _getAccessibilityLabel() {
        const { t, _participantsCount, _isOpen } = this.props;
        if (_isOpen) {
            return t('toolbar.accessibilityLabel.closeParticipantsPane');
        }
        return t('toolbar.accessibilityLabel.participants', {
            participantsCount: _participantsCount
        });
    }
    /**
     * Overrides AbstractButton's {@link Component#render()}.
     *
     * @override
     * @protected
     * @returns {React$Node}
     */
    render() {
        const { _isParticipantsPaneEnabled } = this.props;
        if (!_isParticipantsPaneEnabled) {
            return null;
        }
        return (React.createElement("div", { className: 'toolbar-button-with-badge' }, super.render()));
    }
}
/**
 * Maps part of the Redux state to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @returns {IProps}
 */
function mapStateToProps(state) {
    const { isOpen } = state['features/participants-pane'];
    return {
        _isOpen: isOpen,
        _isParticipantsPaneEnabled: isParticipantsPaneEnabled(state),
        _participantsCount: getParticipantCount(state)
    };
}
export default translate(connect(mapStateToProps)(ParticipantsPaneButton));
