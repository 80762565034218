import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { isMobileBrowser } from '../../../environment/utils';
import Icon from '../../../icons/components/Icon';
import { IconArrowDown } from '../../../icons/svg';
import { withPixelLineHeight } from '../../../styles/functions.web';
const useStyles = makeStyles()(theme => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column'
        },
        label: {
            color: theme.palette.text01,
            ...withPixelLineHeight(theme.typography.bodyShortRegular),
            marginBottom: theme.spacing(2),
            '&.is-mobile': {
                ...withPixelLineHeight(theme.typography.bodyShortRegularLarge)
            }
        },
        selectContainer: {
            position: 'relative'
        },
        select: {
            backgroundColor: theme.palette.ccBackground2,
            borderRadius: `${theme.shape.r2}px`,
            border: `1px solid ${theme.palette.ccBackground4}`,
            width: '100%',
            ...withPixelLineHeight(theme.typography.bodyShortRegular),
            color: theme.palette.text01,
            padding: '10px 16px',
            paddingRight: '42px',
            appearance: 'none',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            '&:focus': {
                outline: 0,
                boxShadow: `0px 0px 0px 2px ${theme.palette.focus01}`
            },
            '&:disabled': {
                color: theme.palette.text03
            },
            '&.is-mobile': {
                ...withPixelLineHeight(theme.typography.bodyShortRegularLarge),
                padding: '12px 16px',
                paddingRight: '46px'
            },
            '&.error': {
                boxShadow: `0px 0px 0px 2px ${theme.palette.textError}`
            }
        },
        icon: {
            position: 'absolute',
            top: '8px',
            right: '8px',
            pointerEvents: 'none',
            '&.is-mobile': {
                top: '12px',
                right: '12px'
            }
        },
        bottomLabel: {
            marginTop: theme.spacing(2),
            ...withPixelLineHeight(theme.typography.labelRegular),
            color: theme.palette.text02,
            '&.is-mobile': {
                ...withPixelLineHeight(theme.typography.bodyShortRegular)
            },
            '&.error': {
                color: theme.palette.textError
            }
        }
    };
});
const Select = ({ bottomLabel, className, disabled, error, id, label, onChange, options, value }) => {
    const { classes, cx, theme } = useStyles();
    const isMobile = isMobileBrowser();
    return (React.createElement("div", { className: classes.container },
        label && React.createElement("label", { className: cx(classes.label, isMobile && 'is-mobile'), htmlFor: id }, label),
        React.createElement("div", { className: classes.selectContainer },
            React.createElement("select", { "aria-describedby": bottomLabel ? `${id}-description` : undefined, className: cx(classes.select, isMobile && 'is-mobile', className, error && 'error'), disabled: disabled, id: id, onChange: onChange, value: value }, options.map(option => (React.createElement("option", { key: option.value, value: option.value }, option.label)))),
            React.createElement(Icon, { className: cx(classes.icon, isMobile && 'is-mobile'), color: disabled ? theme.palette.icon03 : theme.palette.icon01, size: 22, src: IconArrowDown })),
        bottomLabel && (React.createElement("span", { className: cx(classes.bottomLabel, isMobile && 'is-mobile', error && 'error'), id: `${id}-description` }, bottomLabel))));
};
export default Select;
