window.config = {
    hosts: {
        domain: 'meet.jitsi',
        muc: 'muc.meet.jitsi'
    },
    hiddenDomain: 'hidden.meet.jitsi',
    bosh: 'https://connect.rtc.cometchat-staging.com/http-bind',
    websocket: 'wss://connect.rtc.cometchat-staging.com/xmpp-websocket',
    bridgeChannel: {
        preferSctp: true
    },
    disableSelfView: false,
    disableSelfViewSettings: true,
    resolution: 720,
    constraints: {
        video: {
            height: {
                ideal: 720,
                max: 720,
                min: 180
            },
            width: {
                ideal: 1280,
                max: 1280,
                min: 320
            }
        }
    },
    startVideoMuted: 10, // Not working
    startWithVideoMuted: false, // Not working
    flags: {
        sourceNameSignaling: true,
        sendMultipleVideoStreams: true,
        receiveMultipleVideoStreams: true
    },
    enableNoAudioDetection: true,
    enableTalkWhileMuted: false,
    disableAP: false,
    audioQuality: {
        stereo: false
    },
    startAudioOnly: false,
    startAudioMuted: 10, // Not working
    startWithAudioMuted: false, // Not working
    startSilent: false,
    enableOpusRed: false,
    disableAudioLevels: false,
    enableNoisyMicDetection: true,
    p2p: {
        enabled: true
    },
    hideAddRoomButton: false,
    localRecording: {
        disable: true,
        notifyAllParticipants: false,
        disableSelfRecording: true
    },
    analytics: {},
    enableCalendarIntegration: false,
    prejoinConfig: {
        enabled: false,
        hideDisplayName: false
    },
    welcomePage: {
        disabled: true
    },
    enableClosePage: false,
    requireDisplayName: false,
    disableProfile: true,
    roomPasswordNumberOfDigits: false,
    transcription: {
        enabled: false,
        translationLanguages: [],
        translationLanguagesHead: [ 'en' ],
        useAppLanguage: true,
        preferredLanguage: 'en-US',
        disableStartForAll: false,
        autoCaptionOnRecord: false
    },
    deploymentInfo: {},
    disableDeepLinking: true,
    videoQuality: {
        av1: {},
        h264: {},
        vp8: {},
        vp9: {}
    },
    disableReactions: true,
    disablePolls: true,
    remoteVideoMenu: {
        disabled: false,
        disableKick: true,
        disableGrantModerator: true,
        disablePrivateChat: true
    },
    e2eping: {
        enabled: false
    },
    whiteboard: {
        enabled: false
    },
    testing: {
        enableAv1Support: false
    },
    toolbarConfig: {
        alwaysVisible: true
    },
    defaultLogoUrl: 'images/watermark.svg',
    deeplinking: {
        desktop: {
            appName: 'Jitsi Meet',
            appScheme: 'jitsi-meet',
            download: {
                windows: 'https://github.com/jitsi/jitsi-meet-electron/releases/latest/download/jitsi-meet.exe',
                macos: 'https://github.com/jitsi/jitsi-meet-electron/releases/latest/download/jitsi-meet.dmg',
                // eslint-disable-next-line max-len
                linux: 'https://github.com/jitsi/jitsi-meet-electron/releases/latest/download/jitsi-meet-x86_64.AppImage'
            }
        },
        hideLogo: false,
        disabled: true,
        android: {
            appName: 'Jitsi Meet',
            appScheme: 'org.jitsi.meet',
            downloadLink: 'https://play.google.com/store/apps/details?id=org.jitsi.meet',
            appPackage: 'org.jitsi.meet',
            fDroidUrl: 'https://f-droid.org/en/packages/org.jitsi.meet/'
        },
        ios: {
            appName: 'Jitsi Meet',
            appScheme: 'org.jitsi.meet',
            downloadLink: 'https://itunes.apple.com/us/app/jitsi-meet/id1165103905'
        }
    },
    disabledSounds: [],
    raisedHands: {},
    e2ee: {},
    defaultLocalDisplayName: 'me',
    defaultRemoteDisplayName: 'Fellow Jitster',
    recordingService: {},
    liveStreaming: {},
    speakerStats: {},
    disableRemoteMute: true,
    toolbarButtons: [
        'camera',
        'hangup',
        'microphone',
        'raisehand',
        'participants-pane',
        'recording',
        'tileview',
        'toggle-camera',
        'desktop',

        // 'fullscreen',
        'select-background',
        'settings'
    ]
};

// JaaS support: pre-configure image if JAAS_APP_ID was set.
