// @ts-ignore
import JitsiMeetJSLib from 'lib-jitsi-meet';
if (typeof JitsiMeetJSLib === 'string') {
    // eslint-disable-next-line no-eval
    eval(JitsiMeetJSLib);
}
else if (typeof JitsiMeetJSLib === 'object') {
    window.JitsiMeetJS = JitsiMeetJSLib;
}
export default JitsiMeetJS;
