/**
 * Gifs menu.
 *
 * @returns {ReactElement}
 */
function GifsMenu(
// eslint-disable-next-line @typescript-eslint/no-unused-vars
{ columns, parent }) {
    return null;
}
export default GifsMenu;
