import React from 'react';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(theme => {
    return {
        timer: {
            // color: theme.palette.text04,
            color: theme.palette.text01,
            padding: '6px 8px',
            // backgroundColor: '#14141433',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            boxSizing: 'border-box',
            height: '28px',
            borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
            marginRight: '2px',
            fontWeight: theme.typography.cc.body.medium.fontWeight,
            fontSize: `${theme.typography.cc.body.medium.fontSize}px`,
            lineHeight: `${theme.typography.cc.body.medium.lineHeight}px`,
            '@media (max-width: 300px)': {
                display: 'none'
            }
        }
    };
});
/**
 * Returns web element to be rendered.
 *
 * @returns {ReactElement}
 */
export default function ConferenceTimerDisplay({ timerValue, textStyle: _textStyle }) {
    const { classes } = useStyles();
    return (React.createElement("span", { className: classes.timer }, timerValue));
}
