import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { getConferenceName } from '../../../base/conference/functions';
import Tooltip from '../../../base/tooltip/components/Tooltip';
const useStyles = makeStyles()(theme => {
    return {
        container: {
            // color: theme.palette.text04,
            color: theme.palette.text01,
            padding: '2px 16px',
            // backgroundColor: '#14141419',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            maxWidth: '324px',
            boxSizing: 'border-box',
            height: '28px',
            borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
            marginLeft: '2px',
            fontWeight: theme.typography.cc.body.medium.fontWeight,
            fontSize: `${theme.typography.cc.body.medium.fontSize}px`,
            lineHeight: `${theme.typography.cc.body.medium.lineHeight}px`,
            '@media (max-width: 300px)': {
                display: 'none'
            }
        },
        content: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    };
});
/**
 * Label for the conference name.
 *
 * @returns {ReactElement}
 */
const SubjectText = () => {
    const subject = useSelector(getConferenceName);
    const { classes } = useStyles();
    return (React.createElement(Tooltip, { content: subject, position: 'bottom' },
        React.createElement("div", { className: classes.container },
            React.createElement("div", { className: clsx('subject-text--content', classes.content) }, subject))));
};
export default SubjectText;
