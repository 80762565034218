const alertColors = {
    ccInfo: '#0D66BF',
    ccWarning: '#D08D04',
    ccSuccess: '#0B9F5D',
    ccError: '#C73C3E'
};
// Default color palette
export const colors = {
    ...alertColors,
    ccBackground1: '#141414',
    ccBackground2: '#1A1A1A',
    ccBackground3: '#272727',
    ccBackground4: '#383838',
    neutral400: '#4C4C4C',
    neutral500: '#858585',
    neutral600: '#989898',
    error03: '#C73C3E',
    error04: '#C73C3E',
    error05: '#C73C3E',
    error06: '#C73C3E',
    error08: '#C73C3E',
    primary01: '#6852D6',
    primary02: '#5D49BE',
    primary03: '#7965DB',
    primary04: '#8978DF',
    primary05: '#9A8BE4',
    primary06: '#AA9EE8',
    primary07: '#BBB1ED',
    primary08: '#CCC4F1',
    primary09: '#DCD7F6',
    surface01: '#141414',
    surface02: '#1A1A1A',
    surface03: '#434343',
    surface04: '#5B5B5B',
    surface05: '#727272',
    surface06: '#A1A1A1',
    surface07: '#DCDCDC',
    surface08: '#E8E8E8',
    surface09: '#F5F5F5',
    surface10: '#FAFAFA',
    surface11: '#FFF',
    success04: '#189B55',
    support01: '#FF9B42',
    support02: '#F96E57',
    support03: '#DF486F',
    support04: '#B23683',
    support05: '#73348C',
    support06: '#6A50D3',
    support07: '#4380E2',
    support08: '#00A8B3',
    support09: '#2AA076'
};
// Mapping between the token used and the color
export const colorMap = {
    ccBackground1: 'ccBackground1',
    ccBackground2: 'ccBackground2',
    ccBackground3: 'ccBackground3',
    ccBackground4: 'ccBackground4',
    ccTextSecondary: 'neutral600',
    borderLight: 'ccBackground3',
    borderDark: 'neutral400',
    iconSecondary: 'neutral500',
    neutral400: 'neutral400',
    ccInfo: 'ccInfo',
    ccWarning: 'ccWarning',
    ccSuccess: 'ccSuccess',
    ccError: 'ccError',
    // ----- Surfaces -----
    // Default page background
    uiBackground: 'surface02',
    // Container backgrounds
    ui01: 'surface02',
    ui02: 'surface03',
    ui03: 'surface04',
    ui04: 'surface05',
    ui05: 'surface06',
    ui06: 'surface07',
    ui07: 'surface08',
    ui08: 'surface09',
    ui09: 'surface10',
    ui10: 'surface11',
    // ----- Actions -----
    // Primary
    action01: 'primary01',
    action01Hover: 'primary04',
    action01Active: 'primary02',
    // Secondary
    action02: 'surface10',
    action02Hover: 'surface11',
    action02Active: 'surface09',
    // Destructive
    actionDanger: 'error05',
    actionDangerHover: 'error06',
    actionDangerActive: 'error04',
    // Tertiary
    action03: 'transparent',
    action03Hover: 'surface04',
    action03Active: 'surface03',
    // Disabled
    disabled01: 'surface09',
    // Focus
    focus01: 'primary04',
    // ----- Links -----
    link01: 'primary02',
    link01Hover: 'primary08',
    link01Active: 'primary06',
    // ----- Text -----
    // Primary
    text01: 'surface11',
    // Secondary
    text02: 'surface09',
    // Tertiary
    text03: 'surface07',
    // High-contrast
    text04: 'surface01',
    // Error
    textError: 'error08',
    // ----- Icons -----
    // Primary
    icon01: 'surface11',
    // Secondary
    icon02: 'surface09',
    // Tertiary
    icon03: 'surface07',
    // High-contrast
    icon04: 'surface01',
    // Error
    iconError: 'error06',
    // ----- Forms -----
    field01: 'surface04',
    // ----- Feedback -----
    // Success
    success01: 'ccSuccess',
    success02: 'success04',
    // ----- Support -----
    support01: 'support01',
    support02: 'support02',
    support03: 'support03',
    support04: 'support04',
    support05: 'support05',
    support06: 'support06',
    support07: 'support07',
    support08: 'support08',
    support09: 'support09'
};
export const font = {
    weightRegular: '400',
    weightSemiBold: '600'
};
export const shape = {
    borderRadius: 6,
    circleRadius: 50,
    boxShadow: 'inset 0px -1px 0px rgba(255, 255, 255, 0.15)',
    r2: 8,
    r4: 16,
    r5: 20,
    radiusMax: 1000
};
export const spacing = [0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120, 128];
const ccTypography = {
    title: {
        bold: {
            fontWeight: '700',
            fontSize: 32,
            lineHeight: 38.4
        },
        medium: {
            fontWeight: '500',
            fontSize: 32,
            lineHeight: 38.4
        },
        regular: {
            fontWeight: '400',
            fontSize: 32,
            lineHeight: 38.4
        }
    },
    heading1: {
        bold: {
            fontWeight: '700',
            fontSize: 24,
            lineHeight: 28.8
        },
        medium: {
            fontWeight: '500',
            fontSize: 24,
            lineHeight: 28.8
        },
        regular: {
            fontWeight: '400',
            fontSize: 24,
            lineHeight: 28.8
        }
    },
    heading2: {
        bold: {
            fontWeight: '700',
            fontSize: 20,
            lineHeight: 24
        },
        medium: {
            fontWeight: '500',
            fontSize: 20,
            lineHeight: 24
        },
        regular: {
            fontWeight: '400',
            fontSize: 20,
            lineHeight: 24
        }
    },
    heading3: {
        bold: {
            fontWeight: '700',
            fontSize: 18,
            lineHeight: 21.6
        },
        medium: {
            fontWeight: '500',
            fontSize: 18,
            lineHeight: 21.6
        },
        regular: {
            fontWeight: '400',
            fontSize: 18,
            lineHeight: 21.6
        }
    },
    heading4: {
        bold: {
            fontWeight: '700',
            fontSize: 16,
            lineHeight: 20.8
        },
        medium: {
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 20.8
        },
        regular: {
            fontWeight: '400',
            fontSize: 16,
            lineHeight: 20.8
        }
    },
    body: {
        bold: {
            fontWeight: '700',
            fontSize: 14,
            lineHeight: 19.6
        },
        medium: {
            fontWeight: '500',
            fontSize: 14,
            lineHeight: 19.6
        },
        regular: {
            fontWeight: '400',
            fontSize: 14,
            lineHeight: 19.6
        }
    },
    caption1: {
        bold: {
            fontWeight: '700',
            fontSize: 12,
            lineHeight: 16.8
        },
        medium: {
            fontWeight: '500',
            fontSize: 12,
            lineHeight: 16.8
        },
        regular: {
            fontWeight: '400',
            fontSize: 12,
            lineHeight: 16.8
        }
    },
    caption2: {
        bold: {
            fontWeight: '700',
            fontSize: 10,
            lineHeight: 14
        },
        medium: {
            fontWeight: '500',
            fontSize: 10,
            lineHeight: 14
        },
        regular: {
            fontWeight: '400',
            fontSize: 10,
            lineHeight: 14
        }
    },
    button: {
        bold: {
            fontWeight: '700',
            fontSize: 14,
            lineHeight: 16.8
        },
        medium: {
            fontWeight: '500',
            fontSize: 14,
            lineHeight: 16.8
        },
        regular: {
            fontWeight: '400',
            fontSize: 14,
            lineHeight: 16.8
        }
    },
    link: {
        fontWeight: '400',
        fontSize: 14,
        lineHeight: 16.8
    }
};
export const typography = {
    cc: ccTypography,
    labelRegular: {
        fontSize: 12,
        lineHeight: 16,
        fontWeight: font.weightRegular,
        letterSpacing: 0.16
    },
    labelBold: {
        fontSize: 12,
        lineHeight: 16,
        fontWeight: font.weightSemiBold,
        letterSpacing: 0.16
    },
    bodyShortRegularSmall: {
        fontSize: 10,
        lineHeight: 16,
        fontWeight: font.weightRegular,
        letterSpacing: 0
    },
    bodyShortRegular: {
        fontSize: 14,
        lineHeight: 20,
        fontWeight: font.weightRegular,
        letterSpacing: 0
    },
    bodyShortBold: {
        fontSize: 14,
        lineHeight: 20,
        fontWeight: font.weightSemiBold,
        letterSpacing: 0
    },
    bodyShortRegularLarge: {
        fontSize: 16,
        lineHeight: 22,
        fontWeight: font.weightRegular,
        letterSpacing: 0
    },
    bodyShortBoldLarge: {
        fontSize: 16,
        lineHeight: 22,
        fontWeight: font.weightSemiBold,
        letterSpacing: 0
    },
    bodyLongRegular: {
        fontSize: 14,
        lineHeight: 24,
        fontWeight: font.weightRegular,
        letterSpacing: 0
    },
    bodyLongRegularLarge: {
        fontSize: 16,
        lineHeight: 26,
        fontWeight: font.weightRegular,
        letterSpacing: 0
    },
    bodyLongBold: {
        fontSize: 14,
        lineHeight: 24,
        fontWeight: font.weightSemiBold,
        letterSpacing: 0
    },
    bodyLongBoldLarge: {
        fontSize: 16,
        lineHeight: 26,
        fontWeight: font.weightSemiBold,
        letterSpacing: 0
    },
    heading1: {
        fontSize: 54,
        lineHeight: 64,
        fontWeight: font.weightSemiBold,
        letterSpacing: 0
    },
    heading2: {
        fontSize: 42,
        lineHeight: 50,
        fontWeight: font.weightSemiBold,
        letterSpacing: 0
    },
    heading3: {
        fontSize: 32,
        lineHeight: 40,
        fontWeight: font.weightSemiBold,
        letterSpacing: 0
    },
    heading4: {
        fontSize: 28,
        lineHeight: 36,
        fontWeight: font.weightSemiBold,
        letterSpacing: 0
    },
    heading5: {
        fontSize: 20,
        lineHeight: 28,
        fontWeight: font.weightSemiBold,
        letterSpacing: 0
    },
    heading6: {
        fontSize: 16,
        lineHeight: 26,
        fontWeight: font.weightSemiBold,
        letterSpacing: 0
    }
};
export const breakpoints = {
    values: {
        '0': 0,
        '320': 320,
        '400': 400,
        '480': 480
    }
};
