import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { isDisplayNameVisible, isNameReadOnly } from '../../../base/config/functions.any';
import { isScreenShareParticipantById } from '../../../base/participants/functions';
import DisplayName from '../../../display-name/components/web/DisplayName';
import StatusIndicators from './StatusIndicators';
const useStyles = makeStyles()(() => {
    return {
        nameContainer: {
            display: 'flex',
            overflow: 'hidden',
            '&>div': {
                display: 'flex',
                overflow: 'hidden'
            }
        }
    };
});
const ThumbnailBottomIndicators = ({ className, local, participantId, showStatusIndicators = true, thumbnailType }) => {
    const { classes: styles, cx } = useStyles();
    const _allowEditing = !useSelector(isNameReadOnly);
    const _defaultLocalDisplayName = interfaceConfig.DEFAULT_LOCAL_DISPLAY_NAME;
    const _showDisplayName = useSelector(isDisplayNameVisible);
    const isVirtualScreenshareParticipant = useSelector((state) => isScreenShareParticipantById(state, participantId));
    return (React.createElement("div", { className: cx(className, 'bottom-indicators') },
        showStatusIndicators && React.createElement(StatusIndicators, { audio: !isVirtualScreenshareParticipant, moderator: false, participantID: participantId, screenshare: isVirtualScreenshareParticipant, thumbnailType: thumbnailType }),
        _showDisplayName && (React.createElement("span", { className: styles.nameContainer },
            React.createElement(DisplayName, { allowEditing: local ? _allowEditing : false, displayNameSuffix: local ? _defaultLocalDisplayName : '', elementID: local ? 'localDisplayName' : `participant_${participantId}_name`, participantID: participantId, thumbnailType: thumbnailType })))));
};
export default ThumbnailBottomIndicators;
