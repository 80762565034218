import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import participantsPaneTheme from '../../../base/components/themes/participantsPaneTheme.json';
import { openDialog } from '../../../base/dialog/actions';
import { IconCloseLarge, IconDotsHorizontal } from '../../../base/icons/svg';
import { isLocalParticipantModerator } from '../../../base/participants/functions';
import Button from '../../../base/ui/components/web/Button';
import ClickableIcon from '../../../base/ui/components/web/ClickableIcon';
import { BUTTON_TYPES } from '../../../base/ui/constants.web';
import { findAncestorByClass } from '../../../base/ui/functions.web';
import { isAddBreakoutRoomButtonVisible } from '../../../breakout-rooms/functions';
import MuteEveryoneDialog from '../../../video-menu/components/web/MuteEveryoneDialog';
import { close } from '../../actions.web';
import { getParticipantsPaneOpen, isMoreActionsVisible, isMuteAllVisible } from '../../functions';
import { AddBreakoutRoomButton } from '../breakout-rooms/components/web/AddBreakoutRoomButton';
import { RoomList } from '../breakout-rooms/components/web/RoomList';
import { FooterContextMenu } from './FooterContextMenu';
import MeetingParticipants from './MeetingParticipants';
// import LobbyParticipants from './LobbyParticipants';
// import VisitorsList from './VisitorsList';
const useStyles = makeStyles()(theme => {
    return {
        participantsPane: {
            backgroundColor: theme.palette.ui01,
            flexShrink: 0,
            overflow: 'hidden',
            position: 'relative',
            transition: 'width .16s ease-in-out',
            width: '315px',
            zIndex: 0,
            display: 'flex',
            flexDirection: 'column',
            fontWeight: 600,
            height: '100%',
            [['& > *:first-child', '& > *:last-child']]: {
                flexShrink: 0
            },
            '@media (max-width: 580px)': {
                height: '100dvh',
                position: 'fixed',
                left: 0,
                right: 0,
                top: 0,
                width: '100%'
            }
        },
        container: {
            boxSizing: 'border-box',
            flex: 1,
            overflowY: 'auto',
            position: 'relative',
            padding: `0 ${participantsPaneTheme.panePadding}px`,
            '&::-webkit-scrollbar': {
                display: 'none'
            }
        },
        closeButton: {
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center'
        },
        header: {
            alignItems: 'center',
            boxSizing: 'border-box',
            display: 'flex',
            height: '60px',
            padding: `0 ${participantsPaneTheme.panePadding}px`,
            justifyContent: 'flex-end'
        },
        antiCollapse: {
            fontSize: 0,
            '&:first-child': {
                display: 'none'
            },
            '&:first-child + *': {
                marginTop: 0
            }
        },
        footer: {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: `${theme.spacing(4)} ${participantsPaneTheme.panePadding}px`,
            '& > *:not(:last-child)': {
                marginRight: theme.spacing(3)
            }
        },
        footerMoreContainer: {
            position: 'relative'
        }
    };
});
const ParticipantsPane = () => {
    const { classes, cx } = useStyles();
    const paneOpen = useSelector(getParticipantsPaneOpen);
    const isBreakoutRoomsSupported = useSelector((state) => state['features/base/conference'])
        .conference?.getBreakoutRooms()?.isSupported();
    const showAddRoomButton = useSelector(isAddBreakoutRoomButtonVisible);
    const showFooter = useSelector(isLocalParticipantModerator);
    const showMuteAllButton = useSelector(isMuteAllVisible);
    const showMoreActionsButton = useSelector(isMoreActionsVisible);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [contextOpen, setContextOpen] = useState(false);
    const [searchString, setSearchString] = useState('');
    const onWindowClickListener = useCallback((e) => {
        if (contextOpen && !findAncestorByClass(e.target, classes.footerMoreContainer)) {
            setContextOpen(false);
        }
    }, [contextOpen]);
    useEffect(() => {
        window.addEventListener('click', onWindowClickListener);
        return () => {
            window.removeEventListener('click', onWindowClickListener);
        };
    }, []);
    const onClosePane = useCallback(() => {
        dispatch(close());
    }, []);
    const onDrawerClose = useCallback(() => {
        setContextOpen(false);
    }, []);
    const onMuteAll = useCallback(() => {
        dispatch(openDialog(MuteEveryoneDialog));
    }, []);
    const onToggleContext = useCallback(() => {
        setContextOpen(open => !open);
    }, []);
    if (!paneOpen) {
        return null;
    }
    return (React.createElement("div", { className: cx('participants_pane', classes.participantsPane) },
        React.createElement("div", { className: classes.header },
            React.createElement(ClickableIcon, { accessibilityLabel: t('participantsPane.close', 'Close'), icon: IconCloseLarge, onClick: onClosePane })),
        React.createElement("div", { className: classes.container },
            React.createElement(MeetingParticipants, { searchString: searchString, setSearchString: setSearchString }),
            isBreakoutRoomsSupported && false && React.createElement(RoomList, { searchString: searchString }),
            showAddRoomButton && false && React.createElement(AddBreakoutRoomButton, null)),
        showFooter && false && (React.createElement("div", { className: classes.footer },
            showMuteAllButton && (React.createElement(Button, { accessibilityLabel: t('participantsPane.actions.muteAll'), labelKey: 'participantsPane.actions.muteAll', onClick: onMuteAll, type: BUTTON_TYPES.SECONDARY })),
            showMoreActionsButton && (React.createElement("div", { className: classes.footerMoreContainer },
                React.createElement(Button, { accessibilityLabel: t('participantsPane.actions.moreModerationActions'), icon: IconDotsHorizontal, id: 'participants-pane-context-menu', onClick: onToggleContext, type: BUTTON_TYPES.SECONDARY }),
                React.createElement(FooterContextMenu, { isOpen: contextOpen, onDrawerClose: onDrawerClose, onMouseLeave: onToggleContext })))))));
};
export default ParticipantsPane;
