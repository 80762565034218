import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import Icon from '../../../icons/components/Icon';
import { withPixelLineHeight } from '../../../styles/functions.web';
import { BUTTON_TYPES } from '../../constants.web';
const useStyles = makeStyles()(theme => {
    return {
        button: {
            backgroundColor: theme.palette.action01,
            color: theme.palette.text01,
            borderRadius: theme.shape.borderRadius,
            padding: '8px 16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 0,
            ...withPixelLineHeight(theme.typography.bodyShortBold),
            transition: 'background .2s',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.action01Hover
            },
            '&:active': {
                backgroundColor: theme.palette.action01Active
            },
            '&.focus-visible': {
                outline: 0,
                boxShadow: `0px 0px 0px 2px ${theme.palette.focus01}`
            },
            '& div > svg': {
                fill: theme.palette.icon01
            }
        },
        primary: {},
        secondary: {
            backgroundColor: theme.palette.action02,
            color: theme.palette.text04,
            '&:hover': {
                backgroundColor: theme.palette.ccBackground4
            },
            '&:active': {
                backgroundColor: theme.palette.ccBackground3
            },
            '& div > svg': {
                fill: theme.palette.icon04
            }
        },
        tertiary: {
            backgroundColor: theme.palette.action03,
            borderRadius: `${theme.shape.r2}px`,
            border: `1px solid ${theme.palette.borderDark}`,
            '&:hover': {
                backgroundColor: theme.palette.action03Hover
            },
            '&:active': {
                backgroundColor: theme.palette.action03Active
            }
        },
        destructive: {
            backgroundColor: theme.palette.actionDanger,
            '&:hover': {
                backgroundColor: theme.palette.actionDangerHover
            },
            '&:active': {
                backgroundColor: theme.palette.actionDangerActive
            }
        },
        disabled: {
            backgroundColor: theme.palette.disabled01,
            color: theme.palette.text03,
            '&:hover': {
                backgroundColor: theme.palette.disabled01,
                color: theme.palette.text03
            },
            '&:active': {
                backgroundColor: theme.palette.disabled01,
                color: theme.palette.text03
            },
            '& div > svg': {
                fill: theme.palette.icon03
            }
        },
        iconButton: {
            padding: theme.spacing(2)
        },
        text: {
            fontSize: `${theme.typography.cc.caption1.regular.fontSize}px`,
            lineHeight: `${theme.typography.cc.caption1.regular.lineHeight}px`,
            fontWeight: theme.typography.cc.caption1.regular.fontWeight
        },
        textWithIcon: {
            marginLeft: theme.spacing(2)
        },
        small: {
            padding: '8px 16px',
            ...withPixelLineHeight(theme.typography.labelBold),
            '&.iconButton': {
                padding: theme.spacing(1)
            }
        },
        medium: {},
        large: {
            padding: '13px 16px',
            ...withPixelLineHeight(theme.typography.bodyShortBoldLarge),
            '&.iconButton': {
                padding: '12px'
            }
        },
        fullWidth: {
            width: '100%'
        }
    };
});
const Button = React.forwardRef(({ accessibilityLabel, autoFocus = false, className, disabled, fullWidth, icon, id, isSubmit, label, labelKey, onClick = () => null, onKeyPress = () => null, size = 'medium', testId, type = BUTTON_TYPES.PRIMARY }, ref) => {
    const { classes: styles, cx } = useStyles();
    const { t } = useTranslation();
    return (React.createElement("button", { "aria-label": accessibilityLabel, autoFocus: autoFocus, className: cx(styles.button, styles[type], disabled && styles.disabled, icon && !(labelKey || label) && `${styles.iconButton} iconButton`, styles[size], fullWidth && styles.fullWidth, className), "data-testid": testId, disabled: disabled, ...(id ? { id } : {}), onClick: onClick, onKeyPress: onKeyPress, ref: ref, title: accessibilityLabel, type: isSubmit ? 'submit' : 'button' },
        icon && React.createElement(Icon, { size: 24, src: icon }),
        (labelKey || label) && React.createElement("span", { className: cx(styles.text, icon ? styles.textWithIcon : '') }, labelKey ? t(labelKey) : label)));
});
export default Button;
